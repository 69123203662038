import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';

import { SharedTableComponent } from './components/shared-table/shared-table.component';
import { MaterialModule } from '../material.module';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { SharedSearchComponent } from './components/shared-search/shared-search.component';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { TextTooltipDirective } from './core/directives/text-tooltip.directive';
import { InputRegexDirective } from './core/directives/input-regex.directive';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { MaxContentHeightService } from './services/max-content-height.service';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ScrollToInvalidControlDirective } from './core/directives/scroll-to-invalid-control';
import { TransactionsMappingService } from './core/mapping/transactions.mapping.service';
import { PaymentFileRecordMappingService } from './core/mapping/payment-file-record.mapping.service';
import { BankStatementMappingService } from './core/mapping/bank-statement.mapping.service';
import { MdePopoverModule } from '@material-extended/mde';
import { CloseOnScrollDirective } from './core/directives/close-on-scroll.directive';
import { CountedValuesMappingService } from './core/mapping/counted-values.mapping.service';
import { TransactionCountedValueMatchMappingService } from './core/mapping/transaction-counted-value-match.mapping.service';
import { GroupedDifferencesMappingService } from './core/mapping/grouped-differences.mapping.service';
import { SafeHtmlPipe } from './core/pipes/safe-html-pipe';
import { PaymentFileRecordDetailsMappingService } from './core/mapping/payment-file-record-details.mapping.service';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { RecordSelectionDialogComponent } from './components/record-selection-dialog/record-selection-dialog.component';

@NgModule({
    declarations: [
        SharedTableComponent,
        NavMenuComponent,
        SharedSearchComponent,
        ProfileInfoComponent,
        NoDataComponent,
        TextTooltipDirective,
        InputRegexDirective,
        FormDialogComponent,
        RecordSelectionDialogComponent,
        BreadcrumbsComponent,
        ScrollToInvalidControlDirective,
        CloseOnScrollDirective,
        SafeHtmlPipe,
        AutocompleteInputComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        RouterLinkActive,
        MatSortModule,
        MdePopoverModule
    ],
    exports: [
        SharedTableComponent,
        NavMenuComponent,
        SharedSearchComponent,
        ProfileInfoComponent,
        NoDataComponent,
        TextTooltipDirective,
        InputRegexDirective,
        FormDialogComponent,
        RecordSelectionDialogComponent,
        BreadcrumbsComponent,
        ScrollToInvalidControlDirective,
        AutocompleteInputComponent,
    ],
    providers: [
        MaxContentHeightService,
        TransactionsMappingService,
        PaymentFileRecordMappingService,
        BankStatementMappingService,
        CountedValuesMappingService,
        TransactionCountedValueMatchMappingService,
        GroupedDifferencesMappingService,
        PaymentFileRecordDetailsMappingService
    ],
    bootstrap: []
})
export class SharedModule { }
